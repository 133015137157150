<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
              align-h="between"
              align-v="center"
              class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Clientes
              </h3>
            </b-col>
            <b-col cols="auto">
              <div class="d-flex align-items-center">

                <b-link :to="{ name: 'createClient' }">
                  <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                    {{ "Añadir cliente" }}
                  </b-button>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <!-- header -->
          <!-- separador -->
          <hr class="m-0" />
          <!-- filters -->
          <div class="p-1">
            <div class="col-12">
              <b-row>
                <b-col class="col-11"></b-col>
                <b-col class="col-1 text-right">
                  <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                    <feather-icon icon="FilterIcon" size="20" />
                  </span>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="4">
                    <v-select v-model="province" label="name" :options="selectProvinces" :filterable="true"
                      :searchable="true" :placeholder="'Filtrar por provincia'" />
                  </b-col>
                  <b-col sm="4">
                    <v-select v-model="selected" :options="options" label="name"
                      :placeholder="'Filtrar por estado'"></v-select>
                  </b-col>
                  <b-col sm="4">
                    <div class="d-flex  align-items-center">
                      <b-form-input v-model="searchTerm" :placeholder="'Nombre'" type="text"
                        class="d-inline-block w-auto" />
                      <b-button class="ml-1" @click="handleSearch" variant="primary">
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <b-table hover responsive :items="items" :fields="columns" class="table-white">
            <template #cell(name)="data">
              <b-link :to="{ name: 'viewClient', params: { id: data.item.id } }" class="link">
                {{ data.item.name }}
              </b-link>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                <feather-icon :icon="statusVariantIcon(data.item.active) + 'Icon'" size="14" />
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template>
            <template #cell(active_for_projects)="data">
              <b-badge :variant="statusVariant(data.item.active_for_projects)">
                <feather-icon :icon="statusVariantIcon(data.item.active_for_projects) + 'Icon'" size="14" />
                {{ projectsVariantText(data.item.active_for_projects) }}
              </b-badge>
            </template>
            <template #cell(iva)="data">
              <b-badge :variant="statusVariant(data.item.iva)">
                <feather-icon :icon="statusVariantIcon(data.item.iva) + 'Icon'" size="14" />
                {{ projectsVariantText(data.item.iva) }}
              </b-badge>
            </template>
            <template #cell(total_actives)="data">
              {{ data.item.total_projects_actives }}/{{ data.item.total_projects }}
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link :to="{ name: 'viewClient', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <b-link :to="{ name: 'editClient', params: { id: data.item.id } }" class="d-inline-block px-50 text-dark">
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span class="d-inline-block px-50 text-danger" @click="deleteClient(data.item.id, data.item.name)">
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                {{ 'Por página' }}:
              </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BFormGroup,
  BCol, BBadge, BCollapse,
  BRow, BLink, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import vSelect from 'vue-select'

export default {
  components: {
    BBadge,
    BCard,
    BTable,
    BFormGroup,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BCollapse,
    BInputGroupPrepend,
    vSelect,

  },
  data() {
    return {
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Code',
          key: 'code',
        },
        {
          label: 'CIF',
          key: 'cif',
        },
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Nº de Obras',
          key: 'total_actives',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Obras activas',
          key: 'active_for_projects',
        },
        {
          label: 'Iva',
          key: 'iva',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      province: '',
      options: [
        { value: true, name: 'Activo' },
        { value: false, name: 'Desactivado' }
      ],
      selected: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      visibleFilter: false
    }
  },
  watch: {
    province() {
      console.log('provincia', this.province)
      this.currentPage = 1;
      this.chargeData();
    },
    selected() {
      console.log('status', this.selected)
      this.currentPage = 1;
      this.chargeData();
    }
  },
  computed: {
    ...mapGetters({
      items: 'clients/getItems',
      totalItems: 'clients/getTotalItems',
      selectProvinces: 'provinces/getSelectProvinces',

    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Desactivado',
        true: 'Activo',
        false: 'Desactivado',
      }

      return status => statusText[status]
    },
    projectsVariantText() {
      const statusText = {
        1: 'Sí',
        0: 'No',
        true: 'Sí',
        false: 'No',
      }

      return status => statusText[status]
    },
    statusVariantIcon() {
      const StatusIcon = {
        1: 'CheckCircle',
        0: 'XCircle',
        true: 'CheckCircle',
        false: 'XCircle',
      }

      return status => StatusIcon[status]
    }
  },
  methods: {
    ...mapActions({
      list: 'clients/getListClients',
      delete: 'clients/delete',
      getSelectProvinces: 'provinces/selectProvinces',

    }),
    chargeData() {
      let province = this.province ? this.province.id : ''

      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        active: this.selected,
        province: province
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteClient(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
  async created() {
    await this.chargeData()

    await this.getSelectProvinces({
      page: 1, per_page: 9999, search: '', orderBy: ''
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
